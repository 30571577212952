<template>
	<div class="animated fadeIn">
		<div>
			<h4 class="mb-3">
				Аукцион <span v-if="data.name">{{ data.name }}</span>
				<span v-if="data.status === 0" class="text-primary">
					(Черновик)</span
				>
				<span v-else-if="data.status === 1" class="text-primary">
					(Ожидает начала)</span
				>
				<span v-else-if="data.status === 2" class="text-success">
					(Прием ставок)</span
				>
				<span v-else-if="data.status === 3" class="text-warning">
					(Нет победителя)</span
				>
				<span v-else-if="data.status === 4" class="text-success">
					(Есть победитель)</span
				>
				<span v-else-if="data.status === 5" class="text-danger">
					(Отменен)</span
				>
				<span v-else-if="data.status === 6" class="text-secondary">
					(Утвержден)</span
				>
			</h4>
			<h6 v-if="data.status !== 0">
				<span v-if="data.firstBidWin" class="text-primary"
					>Розыгрыш по первой ставке</span
				>
				<span v-else>Стандартный</span>
			</h6>
			<h6 v-if="data.status === 5">
				{{ getCancelReasonText(data.cancelReason) }}
			</h6>
			<div class="form-sections">
				<div v-if="data.status === 0" class="custom-fieldset">
					<b-row>
						<b-col md="12">
							<b-form-group
								class="form-group-el-select"
								label="Разыгрываемый рейс"
							>
								<el-select-clearable
									v-model="form.playedFlightsSelect.value"
									class="form-control"
									name="responsible"
									placeholder="Начните вводить название рейса"
									:multiple="true"
									:multiple-limit="0"
									:disabled="true"
									:loading="loading"
								>
									<el-option
										v-for="item in form.playedFlightsSelect
											.options"
										:key="item.value"
										:label="item.text"
										:value="item.value"
									/>
								</el-select-clearable>
							</b-form-group>
						</b-col>
					</b-row>
				</div>
				<div
					v-if="data.status !== 0 && data.status !== 5"
					class="custom-fieldset"
				>
					<div class="auction-info">
						<div class="auction-info__header text-primary">
							<span class="auction-info__header-item">
								<span
									v-for="(playedFlight,
									index) in data.playedFlights"
									:key="'index +' + index"
								>
									{{ data.playedFlights[index].routeCode
									}}<br />
								</span>
							</span>
							<span class="auction-info__header-item">
								<span
									v-for="(playedFlight,
									index) in data.playedFlights"
									:key="'idx +' + index"
								>
									<i class="fa fa-clock-o fa-sm mr-2" />{{
										formatDate(
											form.playedFlights[index]
												.firstPointLoadingDatetime
										)
									}}
									{{
										formatTime(
											form.playedFlights[index]
												.firstPointLoadingDatetime
										)
									}}<br />
								</span>
							</span>
							<span class="auction-info__header-item">
								<span
									v-for="(playedFlight,
									index) in data.playedFlights"
									:key="'Idx + ' + index"
								>
									Тип перевозки —
									<i>{{ data.playedFlights[index].type }}</i
									><br />
								</span>
							</span>
							<!--span
                class="auction-info__header-item"
              >
                <span
                  v-for="(playedFlight, index) in data.playedFlights"
                >
                  <i class="fa fa-truck fa-sm mr-2" />{{ form.playedFlights[index].dimension }}<br>
                </span>
              </span-->
							<span class="auction-info__header-item">
								<span
									v-for="(playedFlight,
									index) in data.playedFlights"
									:key="'Index +' + index"
								>
									<span v-if="data.status !== 6">
										<i class="fa fa-ruble fa-sm mr-2" />{{
											numberRUFormat(
												form.playedFlights[index].price
											)
										}}
										{{
											alternativeAmountValue(
												form.playedFlights[index].price
											)
										}}<br />
									</span>
									<span v-else>
										<i class="fa fa-ruble fa-sm mr-2" />{{
											numberRUFormat(
												form.playedFlights[index]
													.endPrice
											)
										}}
										{{
											alternativeAmountValue(
												form.playedFlights[index]
													.endPrice
											)
										}}<br />
									</span>
								</span>
							</span>
						</div>
						<div
							v-if="data.status === 1"
							class="auction-info__body"
						>
							<h1 class="auction-info__body-title">
								Аукцион
							</h1>
							<h2 class="auction-info__body-subtitle">
								Прием ставок откроется в указанное время
							</h2>
							<div class="auction-info__body-dates">
								<div class="auction-info__body-start-date">
									<i class="fa fa-clock-o fa-sm mr-2" />{{
										formatDatetime(data.startDate)
									}}<br />(Начало)
								</div>
								<div class="auction-info__body-end-date">
									<i class="fa fa-clock-o fa-sm mr-2" />{{
										formatDatetime(data.endDate)
									}}<br />(Завершение)
								</div>
							</div>
							<div class="auction-info__body-buttons">
								<b-button
									type="button"
									variant="warning"
									class="auction-info__body-button"
									:disabled="loading"
									:class="{ loading: loading }"
									@click="startAuctionModalShow()"
								>
									Изменить период проведения
								</b-button>
							</div>
						</div>
						<b-row v-else-if="data.status === 2" no-gutters>
							<b-col
								v-if="
									data.bids.length > 0 ||
										data.offers.length > 0
								"
								lg="4"
								class="p-3"
							>
								<div class="auction-info__previous-bet">
									<p class="auction-info__previous-bet-title">
										Предыдущие ставки
									</p>
									<div
										class="auction-info__previous-bet-list"
									>
										<div
											v-for="(bid, index) in data.bids"
											:key="'previous-bet' + index"
											class="auction-info__previous-bet-item"
										>
											<div
												class="auction-info__previous-bet-item-header"
											>
												<div
													class="auction-info__previous-bet-item-date text-primary"
												>
													<i
														class="fa fa-clock-o fa-sm mr-2"
													/>{{
														formatDatetime(
															bid.updatedOn
														)
													}}
												</div>
												<div
													class="auction-info__previous-bet-item-type text-success"
												>
													Ставка
												</div>
											</div>
											<div
												class="auction-info__previous-bet-item-info"
											>
												<div
													class="auction-info__previous-bet-item-contractor text-primary"
												>
													{{ bid.contractor.name }}
												</div>
												<div
													class="auction-info__previous-bet-item-price text-success"
												>
													<i
														class="fa fa-ruble fa-sm mr-2"
													/>{{
														numberRUFormat(bid.bet)
													}}
													{{
														alternativeAmountValue(
															bid.bet
														)
													}}
												</div>
											</div>
											<div
												class="auction-info__previous-bet-item-detail"
											>
												<div
													v-for="sum in bid.summs"
													:key="sum.id"
													class=""
												>
													<span
														class="auction-info__previous-bet-item-detail-title"
													>
														{{
															auctionRoutes[
																sum.id
															].route.routeCode
														}}
													</span>
													<span
														class="auction-info__previous-bet-item-detail-value"
													>
														<i
															class="fa fa-ruble fa-sm ml-2 mr-2"
														/>{{
															numberRUFormat(
																sum.price
															)
														}}
													</span>
												</div>
											</div>
											<div
												class="auction-info__previous-bet-item-buttons"
											>
												<b-button
													v-if="
														smallestContractorsBids.get(
															bid.contractor.id
														).id === bid.id
													"
													class="auction-info__previous-bet-item-button"
													variant="success"
													size="sm"
													:class="{
														loading: loading,
													}"
													disabled
													@click="
														setWinner('bid', bid.id)
													"
												>
													Назначить победителем
												</b-button>
											</div>
										</div>
										<div
											v-for="(offer,
											index) in data.offers"
											:key="'previous-offer' + index"
											class="auction-info__previous-bet-item"
										>
											<div
												class="auction-info__previous-bet-item-header"
											>
												<div
													class="auction-info__previous-bet-item-date text-primary"
												>
													<i
														class="fa fa-clock-o fa-sm mr-2"
													/>{{
														formatDatetime(
															offer.updatedOn
														)
													}}
												</div>
												<div
													class="auction-info__previous-bet-item-type text-warning"
												>
													Встречное
												</div>
											</div>
											<div
												class="auction-info__previous-bet-item-info"
											>
												<div
													class="auction-info__previous-bet-item-contractor text-primary"
												>
													{{ offer.contractor.name }}
												</div>
												<div
													class="auction-info__previous-bet-item-price text-success"
												>
													<i
														class="fa fa-ruble fa-sm mr-2"
													/>{{
														numberRUFormat(
															offer.bet
														)
													}}
													{{
														alternativeAmountValue(
															offer.bet
														)
													}}
												</div>
											</div>
											<div
												class="auction-info__previous-bet-item-detail"
											>
												<div
													v-for="sum in offer.summs"
													:key="sum.id"
													class=""
												>
													<span
														class="auction-info__previous-bet-item-detail-title"
													>
														{{
															auctionRoutes[
																sum.id
															].route.routeCode
														}}
													</span>
													<span
														class="auction-info__previous-bet-item-detail-value"
													>
														<i
															class="fa fa-ruble fa-sm ml-2 mr-2"
														/>{{
															numberRUFormat(
																sum.price
															)
														}}
													</span>
												</div>
											</div>
											<div
												class="auction-info__previous-bet-item-buttons"
											>
												<b-button
													class="auction-info__previous-bet-item-button"
													variant="success"
													size="sm"
													:class="{
														loading: loading,
													}"
													disabled
													@click="
														setWinner(
															'offer',
															offer.id
														)
													"
												>
													Назначить победителем
												</b-button>
											</div>
										</div>
									</div>
								</div>
							</b-col>
							<b-col v-if="data.activeBid" lg="8" class="p-3">
								<div class="auction-info__last-bet">
									<p class="auction-info__last-bet-title">
										Аукцион
									</p>
									<p
										class="auction-info__last-bet-time text-secondary"
									>
										<i class="fa fa-clock-o fa-sm mr-2" />{{
											formatDatetime(data.endDate)
										}}<br />
										<span
											v-if="data.countdown"
											class="text-danger"
											><i
												class="fa fa-clock-o fa-sm mr-2"
											/>{{ data.countdown }}</span
										>
									</p>
									<div
										class="auction-info__last-bet-current-bid"
									>
										<span
											class="auction-info__last-bet-current-bid-title"
											>Актуальная ставка</span
										>
										<span
											class="auction-info__last-bet-current-bid-value"
										>
											{{
												numberRUFormat(
													data.activeBid.bet
												)
											}}<i
												class="fa fa-ruble fa-sm ml-2 mr-2"
											/>
											<span
												class="auction-info__last-bet-current-bid-value-vat"
												>{{
													alternativeAmountValue(
														data.activeBid.bet
													)
												}}</span
											>
										</span>
									</div>
									<div>
										<div
											v-for="sum in data.activeBid.summs"
											:key="sum.id"
											class="auction-info__last-bet-current-bid-detail"
										>
											<span
												class="auction-info__last-bet-current-bid-detail-title"
											>
												{{
													auctionRoutes[sum.id].route
														.routeCode
												}}
											</span>
											<span
												class="auction-info__last-bet-current-bid-detail-value"
											>
												{{ numberRUFormat(sum.price)
												}}<i
													class="fa fa-ruble fa-sm ml-2 mr-2"
												/>
											</span>
										</div>
									</div>
									<div
										class="auction-info__last-bet-current-contractor"
									>
										<span
											class="auction-info__last-bet-current-contractor-title"
											>Перевозчик</span
										>
										<span
											class="auction-info__last-bet-current-contractor-value"
											>{{
												data.activeBid.contractor.name
											}}</span
										>
									</div>
									<div
										class="auction-info__last-bet-current-time"
									>
										<span
											class="auction-info__last-bet-current-time-title"
											>Время подачи ставки</span
										>
										<span
											class="auction-info__last-bet-current-time-value"
											>{{
												formatDatetime(
													data.activeBid.updatedOn
												)
											}}</span
										>
									</div>
									<div class="auction-info__last-bet-buttons">
										<b-button
											class="auction-info__last-bet-button"
											variant="success"
											:class="{ loading: loading }"
											disabled
											@click="
												setWinner(
													'bid',
													data.activeBid.id
												)
											"
										>
											Назначить победителем
										</b-button>
									</div>
								</div>
							</b-col>
							<b-col v-else lg="8" class="p-3">
								<div class="auction-info__last-bet">
									<p class="auction-info__last-bet-title">
										Аукцион
									</p>
									<p
										class="auction-info__last-bet-time text-secondary"
									>
										<i class="fa fa-clock-o fa-sm mr-2" />{{
											formatDatetime(data.endDate)
										}}<br />
										<span
											v-if="data.countdown"
											class="text-danger"
											><i
												class="fa fa-clock-o fa-sm mr-2"
											/>{{ data.countdown }}</span
										>
									</p>
									<div
										class="auction-info__last-bet-current-bid"
									>
										<span
											class="auction-info__last-bet-current-bid-title"
											>Финальная ставка</span
										>
										<span
											class="auction-info__last-bet-current-bid-value auction-info__last-bet-current-bid-value_no-winner"
											>Не выявлена</span
										>
									</div>
									<div
										class="auction-info__last-bet-current-contractor"
									>
										<span
											class="auction-info__last-bet-current-contractor-title"
											>Победитель</span
										>
										<span
											class="auction-info__last-bet-current-contractor-value auction-info__last-bet-current-contractor-value_no-winner"
											>Не определен</span
										>
									</div>
									<div
										class="auction-info__last-bet-current-time"
									>
										<span
											class="auction-info__last-bet-current-time-title"
											>Время подачи ставки</span
										>
										<span
											class="auction-info__last-bet-current-time-value auction-info__last-bet-current-time-value_no-winner"
											>Нет</span
										>
									</div>
								</div>
							</b-col>
						</b-row>
						<b-row v-else-if="data.status === 3" no-gutters>
							<b-col
								v-if="
									data.bids.length > 0 ||
										data.offers.length > 0
								"
								lg="4"
								class="p-3"
							>
								<div class="auction-info__previous-bet">
									<p class="auction-info__previous-bet-title">
										Предыдущие ставки
									</p>
									<div
										class="auction-info__previous-bet-list"
									>
										<div
											v-for="(bid, index) in data.bids"
											:key="'previous-bet' + index"
											class="auction-info__previous-bet-item"
										>
											<div
												class="auction-info__previous-bet-item-header"
											>
												<div
													class="auction-info__previous-bet-item-date text-primary"
												>
													<i
														class="fa fa-clock-o fa-sm mr-2"
													/>{{
														formatDatetime(
															bid.updatedOn
														)
													}}
												</div>
												<div
													class="auction-info__previous-bet-item-type text-success"
												>
													Ставка
												</div>
											</div>
											<div
												class="auction-info__previous-bet-item-info"
											>
												<div
													class="auction-info__previous-bet-item-contractor text-primary"
												>
													{{ bid.contractor.name }}
												</div>
												<div
													class="auction-info__previous-bet-item-price text-success"
												>
													<i
														class="fa fa-ruble fa-sm mr-2"
													/>{{
														numberRUFormat(bid.bet)
													}}
													{{
														alternativeAmountValue(
															bid.bet
														)
													}}
												</div>
											</div>
											<div
												class="auction-info__previous-bet-item-buttons"
											>
												<b-button
													v-if="
														smallestContractorsBids.get(
															bid.contractor.id
														).id === bid.id
													"
													class="auction-info__previous-bet-item-button"
													variant="success"
													size="sm"
													:class="{
														loading: loading,
													}"
													:disabled="loading"
													@click="
														setWinner('bid', bid.id)
													"
												>
													Назначить победителем
												</b-button>
											</div>
										</div>
										<div
											v-for="(offer,
											index) in data.offers"
											:key="'previous-offer' + index"
											class="auction-info__previous-bet-item"
										>
											<div
												class="auction-info__previous-bet-item-header"
											>
												<div
													class="auction-info__previous-bet-item-date text-primary"
												>
													<i
														class="fa fa-clock-o fa-sm mr-2"
													/>{{
														formatDatetime(
															offer.updatedOn
														)
													}}
												</div>
												<div
													class="auction-info__previous-bet-item-type text-warning"
												>
													Встречное
												</div>
											</div>
											<div
												class="auction-info__previous-bet-item-info"
											>
												<div
													class="auction-info__previous-bet-item-contractor text-primary"
												>
													{{ offer.contractor.name }}
												</div>
												<div
													class="auction-info__previous-bet-item-price text-success"
												>
													<i
														class="fa fa-ruble fa-sm mr-2"
													/>{{ offer.bet }}
													{{
														alternativeAmountValue(
															offer.bet
														)
													}}
												</div>
											</div>
											<div
												class="auction-info__previous-bet-item-detail"
											>
												<div
													v-for="sum in offer.summs"
													:key="sum.id"
													class=""
												>
													<span
														class="auction-info__previous-bet-item-detail-title"
													>
														{{
															auctionRoutes[
																sum.id
															].route.routeCode
														}}
													</span>
													<span
														class="auction-info__previous-bet-item-detail-value"
													>
														<i
															class="fa fa-ruble fa-sm ml-2 mr-2"
														/>{{
															numberRUFormat(
																sum.price
															)
														}}
													</span>
												</div>
											</div>
											<div
												class="auction-info__previous-bet-item-buttons"
											>
												<b-button
													class="auction-info__previous-bet-item-button"
													variant="success"
													size="sm"
													:class="{
														loading: loading,
													}"
													:disabled="loading"
													@click="
														setWinner(
															'offer',
															offer.id
														)
													"
												>
													Назначить победителем
												</b-button>
											</div>
										</div>
									</div>
								</div>
							</b-col>
							<b-col lg="8">
								<div class="auction-info__last-bet">
									<p class="auction-info__last-bet-title">
										Аукцион
									</p>
									<p
										class="auction-info__last-bet-time text-secondary"
									>
										<i class="fa fa-clock-o fa-sm mr-2" />{{
											formatDatetime(data.endDate)
										}}<br />(Завершение)
									</p>
									<div
										class="auction-info__last-bet-current-bid"
									>
										<span
											class="auction-info__last-bet-current-bid-title"
											>Финальная ставка</span
										>
										<span
											class="auction-info__last-bet-current-bid-value auction-info__last-bet-current-bid-value_no-winner"
											>Не выявлена</span
										>
									</div>
									<div
										class="auction-info__last-bet-current-contractor"
									>
										<span
											class="auction-info__last-bet-current-contractor-title"
											>Победитель</span
										>
										<span
											class="auction-info__last-bet-current-contractor-value auction-info__last-bet-current-contractor-value_no-winner"
											>Не определен</span
										>
									</div>
									<div
										class="auction-info__last-bet-current-time"
									>
										<span
											class="auction-info__last-bet-current-time-title"
											>Время подачи ставки</span
										>
										<span
											class="auction-info__last-bet-current-time-value auction-info__last-bet-current-time-value_no-winner"
											>Нет</span
										>
									</div>
									<p class="auction-info__last-bet-desc">
										Никто из участников не сделал ни одной
										ставки, либо предыдущий победитель
										отказался от выигрыша. Перезапустите
										аукцион, или выберите победителя на
										основе предыдущих ставок.
									</p>
									<div class="auction-info__last-bet-buttons">
										<b-button
											type="button"
											variant="warning"
											class="auction-info__last-bet-button"
											:disabled="loading"
											:class="{ loading: loading }"
											@click="startAuctionModalShow()"
										>
											Перезапустить аукцион
										</b-button>
									</div>
								</div>
							</b-col>
						</b-row>
						<b-row v-else-if="data.status === 4" no-gutters>
							<b-col
								v-if="
									data.bids.length > 0 ||
										data.offers.length > 0
								"
								lg="4"
								class="p-3"
							>
								<div class="auction-info__previous-bet">
									<p class="auction-info__previous-bet-title">
										Предыдущие ставки
									</p>
									<div
										class="auction-info__previous-bet-list"
									>
										<div
											v-for="(bid, index) in data.bids"
											:key="'previous-bet' + index"
											class="auction-info__previous-bet-item"
										>
											<div
												class="auction-info__previous-bet-item-header"
											>
												<div
													class="auction-info__previous-bet-item-date text-primary"
												>
													<i
														class="fa fa-clock-o fa-sm mr-2"
													/>{{
														formatDatetime(
															bid.updatedOn
														)
													}}
												</div>
												<div
													class="auction-info__previous-bet-item-type text-success"
												>
													Ставка
												</div>
											</div>
											<div
												class="auction-info__previous-bet-item-info"
											>
												<div
													class="auction-info__previous-bet-item-contractor text-primary"
												>
													{{ bid.contractor.name }}
												</div>
												<div
													class="auction-info__previous-bet-item-price text-success"
												>
													<i
														class="fa fa-ruble fa-sm mr-2"
													/>{{ bid.bet }}
													{{
														alternativeAmountValue(
															bid.bet
														)
													}}
												</div>
											</div>
											<div
												class="auction-info__previous-bet-item-detail"
											>
												<div
													v-for="sum in bid.summs"
													:key="sum.id"
													class=""
												>
													<span
														class="auction-info__previous-bet-item-detail-title"
													>
														{{
															auctionRoutes[
																sum.id
															].route.routeCode
														}}
													</span>
													<span
														class="auction-info__previous-bet-item-detail-value"
													>
														<i
															class="fa fa-ruble fa-sm ml-2 mr-2"
														/>{{
															numberRUFormat(
																sum.price
															)
														}}
													</span>
												</div>
											</div>
											<div
												class="auction-info__previous-bet-item-buttons"
											>
												<b-button
													v-if="
														smallestContractorsBids.get(
															bid.contractor.id
														).id === bid.id
													"
													class="auction-info__previous-bet-item-button"
													variant="success"
													size="sm"
													:class="{
														loading: loading,
													}"
													:disabled="loading"
													@click="
														setWinner('bid', bid.id)
													"
												>
													Назначить победителем
												</b-button>
											</div>
										</div>
										<div
											v-for="(offer,
											index) in data.offers"
											:key="'previous-offer' + index"
											class="auction-info__previous-bet-item"
										>
											<div
												class="auction-info__previous-bet-item-header"
											>
												<div
													class="auction-info__previous-bet-item-date text-primary"
												>
													<i
														class="fa fa-clock-o fa-sm mr-2"
													/>{{
														formatDatetime(
															offer.updatedOn
														)
													}}
												</div>
												<div
													class="auction-info__previous-bet-item-type text-warning"
												>
													Встречное
												</div>
											</div>
											<div
												class="auction-info__previous-bet-item-info"
											>
												<div
													class="auction-info__previous-bet-item-contractor text-primary"
												>
													{{ offer.contractor.name }}
												</div>
												<div
													class="auction-info__previous-bet-item-price text-success"
												>
													<i
														class="fa fa-ruble fa-sm mr-2"
													/>{{ offer.bet }}
													{{
														alternativeAmountValue(
															offer.bet
														)
													}}
												</div>
											</div>
											<div
												class="auction-info__previous-bet-item-detail"
											>
												<div
													v-for="sum in offer.summs"
													:key="sum.id"
													class=""
												>
													<span
														class="auction-info__previous-bet-item-detail-title"
													>
														{{
															auctionRoutes[
																sum.id
															].route.routeCode
														}}
													</span>
													<span
														class="auction-info__previous-bet-item-detail-value"
													>
														<i
															class="fa fa-ruble fa-sm ml-2 mr-2"
														/>{{
															numberRUFormat(
																sum.price
															)
														}}
													</span>
												</div>
											</div>
											<div
												class="auction-info__previous-bet-item-buttons"
											>
												<b-button
													class="auction-info__previous-bet-item-button"
													variant="success"
													size="sm"
													:class="{
														loading: loading,
													}"
													:disabled="loading"
													@click="
														setWinner(
															'offer',
															offer.id
														)
													"
												>
													Назначить победителем
												</b-button>
											</div>
										</div>
									</div>
								</div>
							</b-col>
							<b-col v-if="data.activeBid" lg="8" class="p-3">
								<div class="auction-info__last-bet">
									<p class="auction-info__last-bet-title">
										Аукцион
									</p>
									<p
										class="auction-info__last-bet-time text-secondary"
									>
										<i class="fa fa-clock-o fa-sm mr-2" />{{
											formatDatetime(data.endDate)
										}}<br />
										(Завершен)
									</p>
									<div
										class="auction-info__last-bet-current-bid"
									>
										<span
											class="auction-info__last-bet-current-bid-title"
											>Финальная ставка</span
										>
										<span
											class="auction-info__last-bet-current-bid-value"
										>
											{{
												numberRUFormat(
													data.activeBid.bet
												)
											}}<i
												class="fa fa-ruble fa-sm ml-2 mr-2"
											/>
											<span
												class="auction-info__last-bet-current-bid-value-vat"
												>{{
													alternativeAmountValue(
														data.activeBid.bet
													)
												}}</span
											>
										</span>
									</div>
									<div>
										<div
											v-for="sum in data.activeBid.summs"
											:key="sum.id"
											class="auction-info__last-bet-current-bid-detail"
										>
											<span
												class="auction-info__last-bet-current-bid-detail-title"
											>
												{{
													auctionRoutes[sum.id].route
														.routeCode
												}}
											</span>
											<span
												class="auction-info__last-bet-current-bid-detail-value"
											>
												{{ numberRUFormat(sum.price)
												}}<i
													class="fa fa-ruble fa-sm ml-2 mr-2"
												/>
											</span>
										</div>
									</div>
									<div
										class="auction-info__last-bet-current-contractor"
									>
										<span
											class="auction-info__last-bet-current-contractor-title"
											>Перевозчик</span
										>
										<span
											class="auction-info__last-bet-current-contractor-value"
											>{{
												data.activeBid.contractor.name
											}}</span
										>
									</div>
									<div
										class="auction-info__last-bet-current-time"
									>
										<span
											class="auction-info__last-bet-current-time-title"
											>Время подачи ставки</span
										>
										<span
											class="auction-info__last-bet-current-time-value"
											>{{
												formatDatetime(
													data.activeBid.updatedOn
												)
											}}</span
										>
									</div>
									<p class="auction-info__last-bet-desc">
										По итогам аукциона определился
										победитель, ожидайте, пока он заберет
										разыгранный рейс в обработку.
									</p>
								</div>
							</b-col>
						</b-row>
						<b-row v-else-if="data.status === 6" no-gutters>
							<b-col v-if="data.activeBid" lg="12" class="p-3">
								<div class="auction-info__last-bet">
									<p class="auction-info__last-bet-title">
										Аукцион
									</p>
									<p
										class="auction-info__last-bet-time text-secondary"
									>
										<i class="fa fa-clock-o fa-sm mr-2" />{{
											formatDatetime(data.endDate)
										}}<br />
										(Завершен)
									</p>
									<div
										class="auction-info__last-bet-current-bid"
									>
										<span
											class="auction-info__last-bet-current-bid-title"
											>Финальная ставка</span
										>
										<span
											class="auction-info__last-bet-current-bid-value"
										>
											{{
												numberRUFormat(
													data.activeBid.bet
												)
											}}<i
												class="fa fa-ruble fa-sm ml-2 mr-2"
											/>
											<span
												class="auction-info__last-bet-current-bid-value-vat"
												>{{
													alternativeAmountValue(
														data.activeBid.bet
													)
												}}</span
											>
										</span>
									</div>
									<div>
										<div
											v-for="sum in data.activeBid.summs"
											:key="sum.id"
											class="auction-info__last-bet-current-bid-detail"
										>
											<span
												class="auction-info__last-bet-current-bid-detail-title"
											>
												{{
													auctionRoutes[sum.id].route
														.routeCode
												}}
											</span>
											<span
												class="auction-info__last-bet-current-bid-detail-value"
											>
												{{ numberRUFormat(sum.price)
												}}<i
													class="fa fa-ruble fa-sm ml-2 mr-2"
												/>
											</span>
										</div>
									</div>
									<div
										class="auction-info__last-bet-current-contractor"
									>
										<span
											class="auction-info__last-bet-current-contractor-title"
											>Перевозчик</span
										>
										<span
											class="auction-info__last-bet-current-contractor-value"
											>{{
												data.activeBid.contractor.name
											}}</span
										>
									</div>
									<div
										class="auction-info__last-bet-current-time"
									>
										<span
											class="auction-info__last-bet-current-time-title"
											>Время подачи ставки</span
										>
										<span
											class="auction-info__last-bet-current-time-value"
											>{{
												formatDatetime(
													data.activeBid.updatedOn
												)
											}}</span
										>
									</div>
									<p class="auction-info__last-bet-desc">
										Аукцион утвержден, рейс взят в
										обработку.
									</p>
								</div>
							</b-col>
						</b-row>
					</div>
				</div>
				<div
					v-for="(playedFlight, index) in data.playedFlights"
					:key="'played-route-' + index"
					class="custom-fieldset"
				>
					<h5 class="custom-fieldset__title">
						<i class="fa fa-map-o fa-lg mr-2" />Маршрут и детали
						рейса {{ data.playedFlights[index].routeCode }}
					</h5>
					<!--b-form-group
            v-if="data.status !== 0"
            class="form-group-el-select"
            label="Разыгрываемый рейс"
            description="Разыгрываемый рейс указан на стадии черновика аукциона"
          >
            <el-select
              v-model="form.playedFlight.value"
              class="form-control"
              name="responsible"
              placeholder="Начните вводить название рейса"
              :disabled="true"
              :loading="loading"
            >
              <el-option
                v-for="item in form.playedFlight.options"
                :key="item.value"
                :label="item.text"
                :value="item.value"
              />
            </el-select>
          </b-form-group-->
					<b-row
						v-if="data.playedFlights[index]"
						class="route-and-flight-auction-details"
					>
						<b-col xl="6" class="mb-4">
							<div
								v-if="data.playedFlights[index].points"
								class="route-and-flight-auction-details__route-container"
							>
								<h6
									class="route-and-flight-auction-details__route-container-title route-and-flight-auction-details__title custom-fieldset__title"
								>
									Маршрут
								</h6>
								<p
									class="route-and-flight-auction-details__route-container-desc"
								>
									В порядке следования рейса
								</p>
								<div
									v-if="
										data.playedFlights[index].points
											.length > 0
									"
									class="route-auction"
								>
									<div
										v-for="point in data.playedFlights[
											index
										].points"
										:key="'point-' + point.rowNumber"
										class="route-auction__item"
									>
										<div class="route-auction__item-header">
											<div
												class="route-auction__item-number"
											>
												Пункт №{{ point.rowNumber }}
											</div>
											<button
												v-if="point.rowNumber === 1"
												class="route-auction__item-type btn btn-square btn-block btn-secondary"
												disabled
											>
												Загрузка
											</button>
											<button
												v-else-if="
													point.rowNumber ===
														data.playedFlights[
															index
														].points.length
												"
												class="route-auction__item-type btn btn-square btn-block btn-secondary"
												disabled
											>
												Разгрузка
											</button>
											<button
												v-else
												class="route-auction__item-type btn btn-square btn-block btn-secondary"
												disabled
											>
												Загрузка/Разгрузка
											</button>
											<div
												class="route-auction__item-distance"
											>
												Расстояние:
												{{
													numberRUFormat(
														Number(
															point.distance
														).toFixed(2)
													)
												}}
												км.
											</div>
										</div>
										<p
											v-if="
												point.department.mainWarehouse
													.address
											"
											class="route-auction__item-address"
										>
											{{
												point.department.mainWarehouse
													.address
											}}
										</p>
										<div
											v-if="point.rowNumber === 1"
											class="route-auction__item-footer"
										>
											<time
												v-if="
													form.playedFlights[index]
														.firstPointLoadingDatetime
												"
												class="route-auction__item-date"
											>
												<i
													class="fa fa-calendar fa-sm mr-3"
												/>{{
													formatDate(
														form.playedFlights[
															index
														]
															.firstPointLoadingDatetime
													)
												}}
											</time>
											<time
												v-if="
													form.playedFlights[index]
														.firstPointLoadingDatetime
												"
												class="route-auction__item-time"
											>
												<i
													class="fa fa-clock-o fa-sm mr-3"
												/>{{
													formatTime(
														form.playedFlights[
															index
														]
															.firstPointLoadingDatetime
													)
												}}
											</time>
										</div>
									</div>
								</div>
								<p
									class="route-and-flight-auction-details__route-container-total"
								>
									Итоговое расстояние:
									<b
										>{{
											numberRUFormat(
												data.playedFlights[index]
													.fullDistance
											)
										}}
										км</b
									>.
								</p>

								<p
									class="route-and-flight-auction-details__route-container-second"
								>
									Ориентировочное время в пути:
									<b>{{
										data.playedFlights[index].timeSummer
									}}</b>
									<small class="mt-4"><strong> <br> *</strong> Точное время доставки будет указано в транспортной накладной после отправки.</small>
								</p>
							</div>
						</b-col>
						<b-col xl="6">
							<div
								class="route-and-flight-auction-details__dates"
							>
								<h6
									class="route-and-flight-auction-details__title custom-fieldset__title"
								>
									Детали рейса
								</h6>
								<p>
									На основе установленных при создании рейса
								</p>
								<div
									class="route-and-flight-auction-details__date"
								>
									<p
										class="route-and-flight-auction-details__date-title"
									>
										Плановая дата и время <b>прибытия</b> в
										первую точку маршрута:
									</p>
									<span
										class="route-and-flight-auction-details__date-number"
										><i
											class="fa fa-calendar fa-sm mr-3"
										/>{{
											formatDate(
												form.playedFlights[index]
													.firstPointArrivalDatetime
											)
										}}</span
									>
									<span
										class="route-and-flight-auction-details__date-time"
										><i
											class="fa fa-clock-o fa-sm mr-3"
										/>{{
											formatTime(
												form.playedFlights[index]
													.firstPointArrivalDatetime
											)
										}}</span
									>
								</div>
								<div
									class="route-and-flight-auction-details__date"
								>
									<p
										class="route-and-flight-auction-details__date-title"
									>
										Плановая дата и время начала
										<b>погрузки</b> в первой точке маршрута
									</p>
									<span
										class="route-and-flight-auction-details__date-number"
										><i
											class="fa fa-calendar fa-sm mr-3"
										/>{{
											formatDate(
												form.playedFlights[index]
													.firstPointLoadingDatetime
											)
										}}</span
									>
									<span
										class="route-and-flight-auction-details__date-time"
										><i
											class="fa fa-clock-o fa-sm mr-3"
										/>{{
											formatTime(
												form.playedFlights[index]
													.firstPointLoadingDatetime
											)
										}}</span
									>
								</div>
								<p
									class="route-and-flight-auction-details__type"
								>
									<b>Организация</b> —
									{{ form.playedFlights[index].organization }}
								</p>
								<p
									class="route-and-flight-auction-details__type"
								>
									<b>Тип перевозки</b> —
									{{ data.playedFlights[index].type }}
								</p>
								<b-form-group label="Ускоренный">
									<b-form-checkbox
										v-model="
											form.playedFlights[index]
												.accelerated
										"
										disabled
										name="accelerated"
									>
										Да
									</b-form-checkbox>
								</b-form-group>
								<b-form-group
									label="Обязательное наличие пропуска на МКАД"
								>
									<b-form-checkbox
										v-model="
											form.playedFlights[index].mkadPass
										"
										disabled
										name="mkadPass"
									>
										Да
									</b-form-checkbox>
								</b-form-group>
								<b-form-group
									label="Обязательная постановка ранее допущенного водителя"
								>
									<b-form-checkbox
										v-model="
											form.playedFlights[index]
												.retryDriver
										"
										disabled
										name="retryDriver"
									>
										Да
									</b-form-checkbox>
								</b-form-group>
								<b-form-group
									label="Получить стоимость рейса автоматически"
								>
									<b-form-checkbox
										v-model="
											form.playedFlights[index].autoTariff
										"
										disabled
										name="autoTariff"
									>
										Да
									</b-form-checkbox>
								</b-form-group>
								<p
									class="route-and-flight-auction-details__type"
								>
									<b>Стоимость рейса</b>
								</p>
								<!-- <b-form-group>
                  <b-form-input
                    v-model="form.playedFlights[index].price"
                    disabled
                    type="number"
                    autocomplete="false"
                  />
                </b-form-group> -->
								<h5 v-if="data.status !== 6">
									{{
										numberRUFormat(
											form.playedFlights[index].price
										)
									}}
									руб.
								</h5>
								<h5 v-else>
									{{
										numberRUFormat(
											form.playedFlights[index].endPrice
										)
									}}
									руб.
								</h5>
							</div>
							<div
								class="route-and-flight-auction-details__transport"
							>
								<h6
									class="route-and-flight-auction-details__title custom-fieldset__title"
								>
									Транспорт
								</h6>
								<p>
									Укажите требования для транспортных средств
									в рейсе
								</p>
								<b-row>
									<b-col md="6">
										<!-- <b-form-group
                      v-if="form.playedFlights[index].dimension"
                      class="route-and-flight-auction-details__form-group form-group-el-select"
                      label="Габариты"
                    >
                      <el-select
                        v-model="form.playedFlights[index].dimension.value"
                        class="form-control"
                        name="size"
                        placeholder=""
                        :disabled="true"
                        :loading="loading"
                      >
                        <el-option
                          v-for="item in dimensionOptions"
                          :key="item.value"
                          :label="item.text"
                          :value="item.value"
                        />
                      </el-select>
                      <p>{{ dimensionOptions.find((item) => item.value == form.playedFlights[index].dimension.value).text }}</p>
                    </b-form-group> -->
										<b-form-group
											v-if="
												form.playedFlights[index]
													.loadingUnloading
											"
											class="route-and-flight-auction-details__form-group form-group-el-select"
											label="Погрузка/Выгрузка"
										>
											<b-form-checkbox-group
												v-model="
													form.playedFlights[index]
														.loadingUnloading.value
												"
												name="loading-unloading"
												stacked
												:disabled="true"
											>
												<b-form-checkbox
													v-for="(item,
													index) in loadingUnloadingOptions"
													:key="index"
													:value="item.value"
												>
													{{ item.text }}
												</b-form-checkbox>
											</b-form-checkbox-group>
										</b-form-group>
										<b-form-group
											class="route-and-flight-auction-details__form-group form-group-el-select"
										>
											<dimensions-view
												v-if="data.status !== 6"
												:value="
													dimensionsList(
														auctionRoutes[
															data.playedFlights[
																index
															].auctionRouteId
														].id,
														auctionRoutes[
															data.playedFlights[
																index
															].auctionRouteId
														].dimension,
														auctionRoutes[
															data.playedFlights[
																index
															].auctionRouteId
														].optionalDimensions
													)
												"
												:price="
													auctionRoutes[
														data.playedFlights[
															index
														].auctionRouteId
													].startPrice
												"
											/>
											<dimensions-view
												v-else
												:value="
													dimensionsList(
														auctionRoutes[
															data.playedFlights[
																index
															].auctionRouteId
														].id,
														auctionRoutes[
															data.playedFlights[
																index
															].auctionRouteId
														].dimension,
														auctionRoutes[
															data.playedFlights[
																index
															].auctionRouteId
														].optionalDimensions
													)
												"
												:price="
													auctionRoutes[
														data.playedFlights[
															index
														].auctionRouteId
													].endPrice
												"
											/>
										</b-form-group>
									</b-col>
									<b-col md="6">
										<b-form-group
											v-if="
												form.playedFlights[index]
													.bodyType
											"
											class="route-and-flight-auction-details__form-group form-group-el-select"
											label="Тип кузова"
										>
											<b-form-checkbox-group
												v-model="
													form.playedFlights[index]
														.bodyType.value
												"
												name="body-type"
												stacked
												:disabled="true"
											>
												<b-form-checkbox
													v-for="(item,
													index) in bodyTypeOptions"
													:key="index"
													:value="item.value"
												>
													{{ item.text }}
												</b-form-checkbox>
											</b-form-checkbox-group>
										</b-form-group>
									</b-col>
								</b-row>	
							</div>
			<div v-if="form.playedFlights[index].note.value && form.playedFlights[index].note.value !== undefined">
				<div class="mt-3 mb-4">
				<b-row>
					<b-col md="4">
						<p class="main-info__item-title">Комментарий к маршруту аукциона</p>
						<p class="main-info__item-desc">
							{{form.playedFlights[index].note.value}}
						</p>
					</b-col>
				</b-row>								
			</div>
        </div>
						</b-col>
					</b-row>
				</div>
				<div class="custom-fieldset">
					<h5 class="custom-fieldset__title">
						<i class="fa fa-gavel fa-lg mr-2" />Аукцион
					</h5>
					<b-tabs>
						<b-tab
							class="terms-and-participants"
							title="Условия и участники"
						>
							<b-row no-gutters>
								<b-col lg="8">
									<div
										class="terms-and-participants__contractors"
									>
										<h6
											class="terms-and-participants__title"
										>
											Участвующие перевозчики
										</h6>
										<p>
											Список участников сформирован на
											стадии черновика аукциона
										</p>
										<v-client-table
											id="dataTable"
											:data="contractors.items"
											:columns="contractors.columns"
											:options="contractors.options"
											:theme="contractors.theme"
										>
											<span
												slot="rating.rating"
												slot-scope="props"
											>
												<span v-if="props.row.rating"
													><span
														:class="{
															'rating-low':
																props.row.rating
																	.rating <=
																30,
															'rating-mid':
																props.row.rating
																	.rating >=
																	31 &&
																props.row.rating
																	.rating <=
																	49,
															'rating-high':
																props.row.rating
																	.rating >=
																50,
														}"
														>{{
															props.row.rating
																.rating
														}}</span
													>
												</span>
												<span
													v-else-if="
														props.row.customers &&
															props.row.customers
																.length > 0 &&
															props.row
																.customers[0]
																.rating
													"
												>
													<span
														:class="{
															'rating-low':
																props.row
																	.customers[0]
																	.rating
																	.rating <=
																30,
															'rating-mid':
																props.row
																	.customers[0]
																	.rating
																	.rating >=
																	31 &&
																props.row
																	.customers[0]
																	.rating
																	.rating <=
																	49,
															'rating-high':
																props.row
																	.customers[0]
																	.rating
																	.rating >=
																50,
														}"
														>{{
															props.row
																.customers[0]
																.rating.rating
														}}</span
													>
												</span>
											</span>
										</v-client-table>
									</div>
								</b-col>
								<b-col lg="4">
									<div
										class="terms-and-participants__start-price"
									>
										<h6
											class="terms-and-participants__title"
										>
											Начальная цена
										</h6>
										<p>
											Стартовая стоимость и шаг ставки
											сформированы на стадии черновика
											аукциона
										</p>
										<b-row>
											<b-col xl="6" lg="12" md="6">
												<!-- <b-form-group>
                          <b-form-input
                            v-model="form.startPrice"
                            type="number"
                            autocomplete="false"
                            disabled
                          />
                        </b-form-group> -->
												<h5 v-if="data.status !== 6">
													{{
														numberRUFormat(
															form.startPrice
														)
													}}
													руб.
												</h5>
												<h5 v-else>
													{{
														numberRUFormat(
															form.endPrice
														)
													}}
													руб.
												</h5>
											</b-col>
											<b-col xl="6" lg="12" md="6">
												<b-form-group
													class="form-group-el-select"
													:description="
														alternativeAmountValue(
															form.startPrice
														)
													"
												>
													<h5>
														<u>{{
															form.VAT.options[
																form.VAT.value
															].text
														}}</u>
													</h5>
													<!-- <el-select
                            v-model="form.VAT.value"
                            class="form-control"
                            name="route-way"
                            :disabled="true"
                            :loading="loading"
                          >
                            <el-option
                              v-for="item in form.VAT.options"
                              :key="item.value"
                              :label="item.text"
                              :value="item.value"
                            />
                          </el-select> -->
												</b-form-group>
											</b-col>
										</b-row>
										<h6
											v-if="form.stepRate"
											class="terms-and-participants__title"
										>
											Шаг ставки
										</h6>
										<!-- <b-form-group>
                      <b-form-input
                        v-model="form.stepRate"
                        type="number"
                        autocomplete="false"
                        disabled
                      />
                    </b-form-group> -->
										<h5>
											{{ numberRUFormat(form.stepRate) }}
											руб.
										</h5>
										<div
											class="terms-and-participants__auction-duration"
										>
											<h6
												class="terms-and-participants__title"
											>
												Период проведения аукциона
											</h6>
											<p v-if="data.status !== 0">
												Период проведения сформирован во
												время запуска аукциона
											</p>
											<p v-else>
												Период проведения аукциона
												назначается непосредственно
												перед запуском.
											</p>
											<div
												v-if="data.status !== 0"
												class="route-and-flight-auction-details__date"
											>
												<p
													class="route-and-flight-auction-details__date-title"
												>
													Старт аукциона:
												</p>
												<span
													class="route-and-flight-auction-details__date-number"
												>
													<i
														class="fa fa-calendar fa-sm mr-3"
													/>{{
														formatDatetime(
															data.startDate
														)
													}}
												</span>
											</div>
											<div
												v-if="data.status !== 0"
												class="route-and-flight-auction-details__date"
											>
												<p
													class="route-and-flight-auction-details__date-title"
												>
													Окончание приема ставок:
												</p>
												<span
													class="route-and-flight-auction-details__date-number"
												>
													<i
														class="fa fa-calendar fa-sm mr-3"
													/>{{
														formatDatetime(
															data.endDate
														)
													}}
												</span>
											</div>
											<div
												v-if="
													startAuctionModal.selected ===
														'enableOffers'
												"
												class="route-and-flight-auction-details__date"
											>
												<p
													class="route-and-flight-auction-details__date-title"
												>
													Прием встречных предложений
													возможен<span
														v-if="
															startAuctionModal.offerDate
														"
														>:</span
													>
												</p>
												<span
													v-if="
														startAuctionModal.offerDate
													"
													class="route-and-flight-auction-details__date-number"
												>
													<i
														class="fa fa-calendar fa-sm mr-3"
													/>{{
														formatDatetime(
															startAuctionModal.offerDate
														)
													}}
												</span>
											</div>
											<div
												v-else-if="
													startAuctionModal.selected ===
														'firstBidWin'
												"
												class="route-and-flight-auction-details__date"
											>
												<p
													class="route-and-flight-auction-details__date-title"
												>
													Розыгрыш победителем по
													первой ставке
												</p>
											</div>
										</div>
									</div>
								</b-col>
							</b-row>
						</b-tab>
						<b-tab title="История">
							<div class="history-auction">
								<h6 class="history-auction__title">
									События
								</h6>
								<p class="history-auction__desc">
									Список событий сформированный на протяжении
									всего существования аукциона
								</p>
								<ul class="history-auction__list">
									<li
										v-for="item in data.history"
										:key="'history-item-' + item.id"
										class="history-auction__list-item"
									>
										<span
											v-if="item.type === 'auctionCreate'"
											class="text-success"
										>
											<span
												class="history-tender__list-item-date"
											>
												{{
													formatDatetimeWithSeconds(
														item.updatedOn
													)
												}}
												<span
													v-if="
														item.manager &&
															item.manager
																.fullname
													"
													>({{
														item.manager.fullname
													}})</span
												>
												<span v-else-if="item.manager"
													>({{
														item.manager.username
													}})</span
												>
											</span>
											Аукцион создан
										</span>
										<span
											v-else-if="item.type === 'newRoute'"
											class="text-primary"
										>
											<span
												class="history-tender__list-item-date"
											>
												{{
													formatDatetimeWithSeconds(
														item.updatedOn
													)
												}}
												<span
													v-if="
														item.manager &&
															item.manager
																.fullname
													"
													>({{
														item.manager.fullname
													}})</span
												>
												<span v-else-if="item.manager"
													>({{
														item.manager.username
													}})</span
												>
											</span>
											Добавлен рейс
											<span
												v-if="
													item.data.route &&
														item.data.route
															.route_code
												"
												>{{
													item.data.route.route_code
												}}</span
											>
										</span>
										<span
											v-else-if="
												item.type === 'updatedRoute'
											"
											class="text-primary"
										>
											<span
												class="history-tender__list-item-date"
											>
												{{
													formatDatetimeWithSeconds(
														item.updatedOn
													)
												}}
												<span
													v-if="
														item.manager &&
															item.manager
																.fullname
													"
													>({{
														item.manager.fullname
													}})</span
												>
												<span v-else-if="item.manager"
													>({{
														item.manager.username
													}})</span
												>
											</span>
											Обновлен рейс
											<span
												v-if="
													item.data.info.route &&
														item.data.info.route
															.route_code
												"
												>{{
													item.data.info.route
														.route_code
												}}</span
											>
											<ol
												v-if="item.data.changes"
												class="history-auction__list-item-changes"
											>
												<li
													v-if="
														item.data.changes &&
															item.data.changes
																.startPrice
													"
												>
													Значение начальной стоимости
													изменено на "{{
														item.data.changes
															.startPrice[1]
													}}
													руб."
												</li>
											</ol>
										</span>
										<span
											v-else-if="item.type === 'newBid'"
											class="text-success"
										>
											<span
												class="history-tender__list-item-date"
											>
												{{
													formatDatetimeWithSeconds(
														item.updatedOn
													)
												}}
												<span
													v-if="
														item.data.contractor &&
															item.data.contractor
																.name
													"
												>
													({{
														item.data.contractor
															.name
													}})
												</span>
											</span>
											Сделана ставка на
											{{
												parseInt(
													item.data.bet
												).toLocaleString()
											}}
											₽
										</span>
										<span
											v-else-if="item.type === 'newOffer'"
											class="text-success"
										>
											<span
												class="history-tender__list-item-date"
											>
												{{
													formatDatetimeWithSeconds(
														item.updatedOn
													)
												}}
												<span
													v-if="
														item.data.contractor &&
															item.data.contractor
																.name
													"
												>
													({{
														item.data.contractor
															.name
													}})
												</span>
											</span>
											Сделано встречное предложение на
											{{
												parseInt(
													item.data.bet
												).toLocaleString()
											}}
											₽
										</span>
										<span
											v-else-if="
												item.type === 'auctionUpdate'
											"
											class="text-primary"
										>
											<span
												class="history-tender__list-item-date"
											>
												{{
													formatDatetimeWithSeconds(
														item.updatedOn
													)
												}}
												<span
													v-if="
														item.manager &&
															item.manager
																.fullname
													"
													>({{
														item.manager.fullname
													}})</span
												>
												<span v-else-if="item.manager"
													>({{
														item.manager.username
													}})</span
												>
											</span>
											Аукцион обновлен:
											<ol
												v-if="item.data"
												class="history-auction__list-item-changes"
											>
												<li v-if="item.data.status">
													Аукцион переведен в статус
													"<span
														v-if="
															item.data
																.status[1] === 0
														"
														>Черновик</span
													>
													<span
														v-else-if="
															item.data
																.status[1] === 1
														"
														>Ожидает начала</span
													>
													<span
														v-else-if="
															item.data
																.status[1] === 2
														"
														>Прием ставок</span
													>
													<span
														v-else-if="
															item.data
																.status[1] === 3
														"
														>Нет победителя</span
													>
													<span
														v-else-if="
															item.data
																.status[1] === 4
														"
														>Есть победитель</span
													>
													<span
														v-else-if="
															item.data
																.status[1] === 5
														"
														>Отменен</span
													>
													<span
														v-else-if="
															item.data
																.status[1] === 6
														"
														>Утвержден</span
													>"
												</li>
												<li v-if="item.data.startDate">
													Дата начала аукциона
													изменена на "{{
														formatDatetime(
															item.data
																.startDate[1]
														)
													}}"
												</li>
												<li v-if="item.data.withNDS">
													Значение НДС изменено на "{{
														item.data.withNDS[1]
															? "С НДС"
															: "Без НДС"
													}}"
												</li>
												<li v-if="item.data.startPrice">
													Значение начальной стоимости
													изменено на "{{
														item.data.startPrice[1]
													}}
													руб."
												</li>
												<li v-if="item.data.step">
													Значение шага изменено на
													"{{ item.data.step[1] }}
													руб."
												</li>
												<li v-if="item.data.dimension">
													<span
														v-if="
															item.data
																.dimension[0]
														"
														>Значение габаритов
														очищено</span
													>
													<span v-else
														>Значение габаритов
														изменено на "{{
															item.data
																.dimension[1]
																.name
														}}"</span
													>
												</li>
												<li
													v-if="
														item.data.loadingTypes
													"
												>
													Значение погрузки/выгрузки
													изменено на "{{
														item.data.loadingTypes
													}}"
												</li>
												<li v-if="item.data.bodyTypes">
													Значение типов кузовов
													изменено на "{{
														item.data.bodyTypes
													}}"
												</li>
												<li v-if="item.data.offerDate">
													Дата начала подачи встречных
													предложений изменена на "{{
														formatDatetime(
															item.data
																.offerDate[1]
														)
													}}"
												</li>
											</ol>
										</span>
										<span
											v-else-if="
												item.type ===
													'changeLoadingTypes'
											"
											class="text-primary"
										>
											<span
												class="history-tender__list-item-date"
											>
												{{
													formatDatetimeWithSeconds(
														item.updatedOn
													)
												}}
												<span
													v-if="
														item.manager &&
															item.manager
																.fullname
													"
													>({{
														item.manager.fullname
													}})</span
												>
												<span v-else-if="item.manager"
													>({{
														item.manager.username
													}})</span
												>
											</span>
											Аукцион обновлен:
											<ol
												class="history-auction__list-item-changes"
											>
												<li v-if="item.data">
													Значение погрузки/выгрузки
													изменено на: "<span
														v-for="(loadingType,
														index) in item.data"
														:key="
															'loading-type-' +
																loadingType.id
														"
														>{{ loadingType.name
														}}{{
															Number(index) !==
															item.data.length - 1
																? ", "
																: ""
														}}</span
													>"
												</li>
											</ol>
										</span>
										<span
											v-else-if="
												item.type ===
													'changeVehicleBodyTypes'
											"
											class="text-primary"
										>
											<span
												class="history-tender__list-item-date"
											>
												{{
													formatDatetimeWithSeconds(
														item.updatedOn
													)
												}}
												<span
													v-if="
														item.manager &&
															item.manager
																.fullname
													"
													>({{
														item.manager.fullname
													}})</span
												>
												<span v-else-if="item.manager"
													>({{
														item.manager.username
													}})</span
												>
											</span>
											Аукцион обновлен:
											<ol
												v-if="item.data"
												class="history-auction__list-item-changes"
											>
												<li v-if="item.data">
													Значение типов кузовов
													изменено на: "<span
														v-for="(bodyType,
														index) in item.data"
														:key="
															'body-type-' +
																bodyType.id
														"
														>{{ bodyType.name
														}}{{
															Number(index) !==
															item.data.length - 1
																? ", "
																: ""
														}}</span
													>"
												</li>
											</ol>
										</span>
									</li>
								</ul>
							</div>
						</b-tab>
					</b-tabs>
				</div>
			</div>
			<div class="fixed-buttons">
				<b-button
					type="button"
					variant="secondary"
					class="mr-2"
					:class="{ loading: loading }"
					@click="$router.go(-1)"
				>
					Назад
				</b-button>
				<a
					v-if="
						$store.state.user.ownerType === 'customer' &&
							data.status === 0
					"
					:href="`/auctions/customer/auction/id${auctionsId}`"
					class="btn btn-primary mr-2"
					:disabled="loading"
					:class="{ loading: loading }"
					>Редактировать</a
				>
				<b-button
					v-if="
						data.status !== 0 &&
							data.status !== 5 &&
							data.status !== 6
					"
					type="button"
					variant="primary"
					class="mr-2"
					:disabled="loading"
					:class="{ loading: loading }"
					@click="onDraft()"
				>
					Вернуть в черновик
				</b-button>
				<b-button
					v-if="
						$store.state.user.ownerType === 'customer' &&
							data.status === 0
					"
					type="button"
					variant="success"
					class="mr-2"
					:disabled="loading"
					:class="{ loading: loading }"
					@click="startAuctionModalShow()"
				>
					Запустить аукцион
				</b-button>
				<b-button
					v-if="
						$store.state.user.ownerType === 'customer' &&
							data.status !== 5 &&
							data.status !== 6
					"
					type="button"
					variant="danger"
					:class="{ loading: loading }"
					:disabled="loading"
					@click="confirmDeleteAuction()"
				>
					Отменить аукцион
				</b-button>
			</div>
		</div>

		<b-modal
			v-model="startAuctionModal.show"
			:title="'Запуск аукциона'"
			class="modal-warning"
			cancel-title="Назад"
			ok-variant="success"
			:ok-disabled="startAuctionDisabled"
			ok-title="Запустить аукцион"
			@ok="onStart()"
		>
			<p>Выберите тип запуска аукциона и назначьте время</p>
			<b-form-group class="form-group-el-select" label="Способ запуска">
				<el-select
					v-model="startAuctionModal.method.value"
					class="form-control"
					name="route-way"
					:loading="loading"
					@change="startAuctionAutoDateSet()"
				>
					<el-option
						v-for="item in startAuctionModal.method.options"
						:key="item.value"
						:label="item.text"
						:value="item.value"
					/>
				</el-select>
			</b-form-group>
			<b-form-group
				v-if="startAuctionModal.method.value"
				label="Старт аукциона"
				label-for="start-auction-modal-start-date"
			>
				<el-date-picker-input
					id="start-auction-modal-start-date"
					v-model="startAuctionModal.startDate"
					type="datetime"
					placeholder=""
					format="dd.MM.yyyy HH:mm"
					:picker-options="pickerOptions"
					@change="startAuctionStartDateChange()"
				/>
			</b-form-group>
			<b-form-group
				label="Окончание приема ставок"
				label-for="start-auction-modal-end-date"
			>
				<el-date-picker-input
					id="start-auction-modal-end-date"
					v-model="startAuctionModal.stopDate"
					type="datetime"
					placeholder=""
					format="dd.MM.yyyy HH:mm"
					:picker-options="pickerOptions"
				/>
			</b-form-group>
			<b-form-group>
				<b-form-radio-group
					stacked
					v-model="startAuctionModal.selected"
				>
					<b-form-radio
						class="mb-3"
						value="standartAuction"
						id="standartAuction"
						name="standart-auction"
						@change="startAuctionAutoDateSet()"
						>Стандартный запуск аукциона</b-form-radio
					>
					<b-form-radio
						class="mb-3"
						value="firstBidWin"
						id="firstBidWin"
						name="first-bid-win"
						@change="startAuctionAutoDateSet()"
						>Розыгрыш победителем по первой ставке</b-form-radio
					>
					<b-form-radio
						value="enableOffers"
						id="enableOffers"
						name="enable-offers"
						@change="startAuctionAutoDateSet()"
						>Возможность подачи встречных предложений</b-form-radio
					>
				</b-form-radio-group>
			</b-form-group>

			<b-form-group v-if="startAuctionModal.selected === 'enableOffers'">
				<el-date-picker-input
					v-model="startAuctionModal.offerDate"
					type="datetime"
					format="dd.MM.yyyy HH:mm"
					placeholder="Дата и время начала подачи встречных предложений"
					:picker-options="pickerOptions"
				/>
				<small class="form-text text-muted"
					>Оставьте поле пустым если хотите получать втречные
					предложения на протяжении всего аукциона</small
				>
			</b-form-group>
		</b-modal>
	</div>
</template>

<script>
import DimensionsView from "../../components/DimensionsView.vue";
import ElDatePickerInput from "../../components/ElDatePickerInput.vue";
import ElSelectClearable from "../../components/ElSelectClearable.vue";
import Vue from "vue";
import { ClientTable, Event } from "vue-tables-2";
import notifications from "../../components/mixins/notifications";
import {
	customersRoutesList,
	transportVehiclesAuctionBodyTypes,
	transportVehiclesLoadingTypes,
	transportVehiclesDimensionsTypes,
	customersAuctionRead,
	customersRouteRead,
	routeWayRead,
	customersAuctionClearRun,
	customersAuctionChangeDates,
	customersAuctionCancel,
	customersAuctionDraft,
	customersAuctionSetWinner,
} from "../../services/api";
import {
	valueToValueWithVAT,
	valueToValueWithoutVAT,
	numberToRUFormat,
} from "../../components/mixins/helpers";
import moment from "moment";
import tz from "moment-timezone";
import { auctionCancelReason } from "../../components/mixins/auctionCancelReason";

Vue.use(ClientTable);

const socket = new WebSocket(process.env.VUE_APP_WEBSOCKET_AUCTION_API_URL);
const urlArray = window.location.pathname.split("/");
const auctionsId = urlArray[urlArray.length - 1].substr(2);

socket.onopen = () => {
	socket.send(
		JSON.stringify({
			event: "authorize",
			data: {
				jwt: localStorage.token,
				auctionId: Number(auctionsId),
			},
		})
	);
};

export default {
	name: "CustomerAuctionsView",
	components: {
		ClientTable,
		Event,
		ElSelectClearable,
		ElDatePickerInput,
		DimensionsView,
	},
	mixins: [notifications, auctionCancelReason],
	props: ["customerId", "auctionsId"],
	data() {
		return {
			note: {
				id: null,
				value: null,
			},
			data: {
				status: null,
				countdown: null,
				countdownInterval: null,
				name: "",
				routeWayName: "",
				dimension: "",
				startDate: "",
				endDate: "",
				history: null,
				playedFlights: {},
				bids: [],
				offers: [],
				activeBid: null,
				firstBidWin: null,
			},
			auctionRoutes: {},
			dimensionOptions: [],
			loadingUnloadingOptions: [],
			bodyTypeOptions: [],
			form: {
				playedFlightsSelect: {
					options: [],
					value: [],
				},
				playedFlights: {},
				VAT: {
					options: [
						{ value: 0, text: "Без НДС" },
						{ value: 1, text: "С НДС" },
					],
					value: 0,
				},
				startPrice: null,
				endPrice: null,
				stepRate: null,
			},
			contractors: {
				columns: ["id", "name", "email", "trucksCount", "rating"],
				options: {
					headings: {
						id: "ID",
						name: "Название",
						email: "Email",
						trucksCount: "Кол-во автомобилей",
						rating: "Рейтинг",
					},
					sortable: ["id", "name", "email", "trucksCount", "rating"],
					filterable: [],
					sortIcon: {
						base: "fa",
						up: "fa-sort-asc",
						down: "fa-sort-desc",
						is: "fa-sort",
					},
					pagination: {
						chunk: 10,
						edge: true,
						nav: "fixed",
					},
					perPage: 5,
					perPageValues: [5, 10, 25],
					texts: {
						count:
							"Показаны с {from} по {to} из {count} записей|{count} записей|Одна запись",
						first: "Первая",
						last: "Последняя",
						filter: "Фильтр:",
						filterPlaceholder: "Поисковый запрос",
						limit: "Записи:",
						page: "Страница:",
						noResults: "Нет совпадающих записей",
						filterBy: "Фильтр по {column}",
						loading: "Загрузка...",
						defaultOption: "Выбор {column}",
						columns: "Столбцы",
					},
				},
				items: [],
				theme: "bootstrap4",
			},
			pickerOptions: {
				firstDayOfWeek: 1,
			},
			startAuctionModal: {
				show: false,
				selected: "standartAuction",
				method: {
					options: [
						{ value: 0, text: "Запустить сейчас" },
						{ value: 1, text: "Запустить как отложенный" },
					],
					value: 0,
				},
				startDate: "",
				stopDate: "",
				offerDate: "",
			},
			loading: false,
			useVuex: false,
			template: "default",
			smallestContractorsBids: new Map(),
		};
	},
	computed: {
		dimensionsList(routeId, dimension, optionalDimensions) {
			return (routeId, dimension, optionalDimensions) => {
				return {
					id: routeId,
					...(dimension && { dimension: dimension }),
					...(optionalDimensions?.length > 0 && {
						optionalDimensions: optionalDimensions,
					}),
				};
			};
		},
		startAuctionDisabled() {
			if (this.startAuctionModal.method.value) {
				return !(
					this.startAuctionModal.startDate &&
					this.startAuctionModal.stopDate
				);
			} else {
				return !this.startAuctionModal.stopDate;
			}
		},
		formStrStart() {
			const calculationFormData = {};
			if (this.form.playedFlight.value) {
				calculationFormData.routes = [];
				calculationFormData.routes.push({
					id: this.form.playedFlight.value,
				});
				if (this.data.playedFlight.firstPointArrivalDatetime) {
					calculationFormData.routes[0].planDateOfFirstPointArrive = moment(
						this.data.playedFlight.firstPointArrivalDatetime
					)
						.tz(this.data.playedFlight.firstRouteWayTimezone, true)
						.format();
				}
				if (this.data.playedFlight.firstPointLoadingDatetime) {
					calculationFormData.routes[0].planDateOfFirstPointLoading = moment(
						this.data.playedFlight.firstPointLoadingDatetime
					)
						.tz(this.data.playedFlight.firstRouteWayTimezone, true)
						.format();
				}
				calculationFormData.routes[0].boostFlag = this.data.playedFlight.accelerated;
				calculationFormData.mkadPass = this.data.playedFlight.mkadPass;
				calculationFormData.retryDriver = this.data.playedFlight.retryDriver;
			}
			if (
				this.startAuctionModal.method.value &&
				this.startAuctionModal.startDate
			) {
				calculationFormData.startDate = moment(
					this.startAuctionModal.startDate
				).format();
			}
			if (this.startAuctionModal.stopDate) {
				calculationFormData.stopDate = moment(
					this.startAuctionModal.stopDate
				).format();
			}
			if (this.startAuctionModal.selected === "firstBidWin") {
				calculationFormData.first_bid_win =
					this.startAuctionModal.selected === "firstBidWin";
			}
			if (this.startAuctionModal.selected === "enableOffers") {
				calculationFormData.enable_offers =
					this.startAuctionModal.selected === "enableOffers";
			}
			if (this.startAuctionModal.offerDate) {
				calculationFormData.offerDate = moment(
					this.startAuctionModal.offerDate
				).format();
			}
			return calculationFormData;
		},
		formStrSnakeStart() {
			const calculationFormData = {};
			if (
				this.startAuctionModal.method.value &&
				this.startAuctionModal.startDate
			) {
				calculationFormData.start_date = moment(
					this.startAuctionModal.startDate
				).format();
			}
			if (this.startAuctionModal.stopDate) {
				calculationFormData.stop_date = moment(
					this.startAuctionModal.stopDate
				).format();
			}
			if (this.startAuctionModal.selected === "firstBidWin") {
				calculationFormData.first_bid_win =
					this.startAuctionModal.selected === "firstBidWin";
			}
			if (this.startAuctionModal.selected === "enableOffers") {
				calculationFormData.enable_offers =
					this.startAuctionModal.selected === "enableOffers";
			}
			if (this.startAuctionModal.offerDate) {
				calculationFormData.offer_date = moment(
					this.startAuctionModal.offerDate
				).format();
			}
			return calculationFormData;
		},
	},
	sockets: {
		connect: function() {
			console.log("socket connected");
		},
		customEmit: function(data) {
			console.log(
				'this method was fired by the socket server. eg: io.emit("customEmit", data)'
			);
		},
	},
	watch: {
		$route() {
			this.customersAuctionRead();
		},
	},
	created() {
		socket.onmessage = (message) => {
			try {
				const data = JSON.parse(message.data);
				this.data.bids = [];
				this.data.activeBid = null;
				this.data.offers = [];
				this.form.playedFlightsSelect.options = [];
				this.data.round = data.round;
				this.data.status = data.status;
				if (data.name) {
					this.data.name = data.name;
				}
				if (typeof data.cancelReason !== "undefined") {
					this.data.cancelReason = data.cancelReason;
				}
				if (data.startDate) {
					this.data.startDate = data.startDate;
				}
				if (data.factEndDate) {
					this.data.endDate = data.factEndDate;
				} else if (data.stopDate) {
					if (this.data.endDate !== data.stopDate) {
						this.data.endDate = data.stopDate;

						const eventTime = moment(data.stopDate).format("x");
						const currentTime = moment().format("x");
						const disableTime = 10000;
						const diffTime = eventTime - currentTime - disableTime;
						let duration = moment.duration(diffTime);
						const interval = 1000;

						clearInterval(this.data.countdownInterval);
						this.data.countdownInterval = setInterval(() => {
							duration = moment.duration(
								duration - interval,
								"milliseconds"
							);

						if (duration.days() === 0) {
							this.data.countdown =
								duration.hours() +
								"ч : " +
								duration.minutes() +
								"м " +
								duration.seconds() +
								"сек";
						}
					}, interval);
					}
				}
				if (data.bids.length > 0) {
					data.bids.forEach((bid) => {
						if (bid.round === data.round) {
							if (data.status === 4) {
								if (bid.winner) {
									this.data.activeBid = bid;
								} else {
									this.data.bids.unshift(bid);
								}
							} else if (data.status === 6) {
								if (bid.winner) {
									this.data.activeBid = bid;
								}
							} else {
								if (bid.active) {
									this.data.activeBid = bid;
								} else {
									this.data.bids.unshift(bid);
								}
								if (
									!this.smallestContractorsBids.get(
										bid.contractor.id
									)
								) {
									this.smallestContractorsBids.set(
										bid.contractor.id,
										bid
									);
								} else if (
									this.smallestContractorsBids.get(
										bid.contractor.id
									).bet > bid.bet
								) {
									this.smallestContractorsBids.set(
										bid.contractor.id,
										bid
									);
								}
							}
						}
					});
					this.data.bids.sort((a, b) => b.id - a.id);
				}
				if (data.offers.length > 0) {
					data.offers.forEach((offer) => {
						if (offer.round === data.round) {
							if (data.status === 4) {
								if (offer.winner) {
									this.data.activeBid = offer;
								} else {
									this.data.offers.unshift(offer);
								}
							} else if (data.status === 6) {
								if (offer.winner) {
									this.data.activeBid = offer;
								}
							} else {
								this.data.offers.unshift(offer);
							}
						}
					});
				}
				// playedFlightsSelect start
				this.form.playedFlightsSelect.value = data.auctionRoutes.map(
					(auctionRoute) => auctionRoute.route.id
				);
				this.form.playedFlightsSelect.options = data.auctionRoutes.map(
					(auctionRoute) => {
						return {
							value: auctionRoute.route.id,
							text: auctionRoute.route.routeCode,
						};
					}
				);
				// playedFlightsSelect end
				// auction start
				if (data.firstBidWin) {
					this.startAuctionModal.selected === "firstBidWin";
				}
				if (data.enableOffers) {
					this.startAuctionModal.selected === "enableOffers";
				}
				if (data.offerDate) {
					this.startAuctionModal.offerDate = data.offerDate;
				}
				if (data.startPrice) {
					this.form.startPrice = data.startPrice;
				}
				if (data.endPrice) {
					this.form.endPrice = data.endPrice;
				}
				if (data.step) {
					this.form.stepRate = data.step;
				}
				if (data.withNDS) {
					this.form.VAT.value = 1;
				} else {
					this.form.VAT.value = 0;
				}
				if (data.auctionHistoryMessages) {
					this.data.history = data.auctionHistoryMessages.sort(
						(a, b) => b.id - a.id
					);
				}
				if (data.possibleParticipants.length > 0) {
					this.contractors.items = data.possibleParticipants;
				}
				// auction end
				// auctionRoute start
				data.auctionRoutes.forEach((auctionRoute) => {
					this.auctionRoutes[auctionRoute.id] = auctionRoute;
					Vue.set(this.data.playedFlights, auctionRoute.route.id, {});
					Vue.set(this.form.playedFlights, auctionRoute.route.id, {});
					Vue.set(
						this.data.playedFlights[auctionRoute.route.id],
						"auctionRouteId",
						auctionRoute.id
					);
          Vue.set(
						this.form.playedFlights[auctionRoute.route.id],
						"note",
						{id: null,
						value: null}
					)
					this.customersRouteRead(auctionRoute.route.id);
					if (auctionRoute.dimension) {
						Vue.set(
							this.form.playedFlights[auctionRoute.route.id],
							"dimension",
							{ value: auctionRoute.dimension.id }
						);
					}
					if (auctionRoute.loadingTypes.length > 0) {
						Vue.set(
							this.form.playedFlights[auctionRoute.route.id],
							"loadingUnloading",
							{
								value: auctionRoute.loadingTypes.map(
									(item) => item.id
								),
							}
						);
					}
					if (auctionRoute.bodyTypes.length > 0) {
						Vue.set(
							this.form.playedFlights[auctionRoute.route.id],
							"bodyType",
							{
								value: auctionRoute.bodyTypes.map(
									(item) => item.id
								),
							}
						);
					}
					Vue.set(
						this.form.playedFlights[auctionRoute.route.id],
						"mkadPass",
						auctionRoute.mkadPass
					);
					Vue.set(
						this.form.playedFlights[auctionRoute.route.id],
						"retryDriver",
						auctionRoute.retryDriver
					);
					Vue.set(
						this.form.playedFlights[auctionRoute.route.id],
						"autoTariff",
						auctionRoute.autoTariff
					);
					Vue.set(
						this.form.playedFlights[auctionRoute.route.id],
						"price",
						auctionRoute.startPrice
					);
					Vue.set(
						this.form.playedFlights[auctionRoute.route.id],
						"endPrice",
						auctionRoute.endPrice
					);
				});
				// auctionRoute end
			} catch (e) {
				console.log(e);
			}
		};
		socket.onclose = (event) => {
			console.log("onclose");
			console.log(event);
		};
		socket.onerror = (error) => {
			console.log("onerror");
			console.log("Ошибка " + error.message);
		};
	},
	mounted() {
		this.customersAuctionRead();
		this.transportVehiclesAuctionBodyTypes();
		this.transportVehiclesLoadingTypes();
		this.transportVehiclesDimensionsTypes();
	},
	methods: {
		numberRUFormat(value) {
			return numberToRUFormat(value);
		},
		formatDate(date) {
			return date ? moment(date).format("DD.MM.YYYY") : "";
		},
		formatTime(date) {
			return date ? moment(date).format("HH:mm") : "";
		},
		formatDatetime(date) {
			return date ? moment(date).format("DD.MM.YYYY HH:mm") : "";
		},
		formatDatetimeWithSeconds(date) {
			return date ? moment(date).format("DD.MM.YYYY HH:mm:ss") : "";
		},
		startAuctionModalShow() {
			this.startAuctionModal.show = true;
			this.startAuctionAutoDateSet();
		},
		startAuctionAutoDateSet() {
			if (this.startAuctionModal.selected === "enableOffers") {
				if (!this.startAuctionModal.method.value) {
					this.startAuctionModal.stopDate = moment()
						.add(60, "m")
						.format();
				} else {
					this.startAuctionModal.stopDate = moment(
						this.startAuctionModal.startDate
					)
						.add(60, "m")
						.format();
				}
			} else {
				if (!this.startAuctionModal.method.value) {
					this.startAuctionModal.stopDate = moment()
						.add(30, "m")
						.format();
				} else {
					this.startAuctionModal.stopDate = moment(
						this.startAuctionModal.startDate
					)
						.add(30, "m")
						.format();
				}
			}
		},
		startAuctionStartDateChange() {
			if (this.startAuctionModal.startDate) {
				this.startAuctionAutoDateSet();
			}
		},
		alternativeAmountValue(value) {
			if (value) {
				if (!this.form.VAT.value) {
					return (
						"(" +
						this.numberRUFormat(valueToValueWithVAT(value)) +
						" руб. С НДС)"
					);
				} else {
					return (
						"(" +
						this.numberRUFormat(valueToValueWithoutVAT(value)) +
						" руб. Без НДС)"
					);
				}
			} else {
				return "";
			}
		},
		async customersAuctionRead() {
			this.loading = true;
			const response = await customersAuctionRead(
				this.customerId,
				this.auctionsId
			);
			if (response && response.status === 200) {
				this.data.bids = [];
				this.data.activeBid = null;
				this.data.offers = [];
				this.form.playedFlightsSelect.options = [];
				this.data.round = response.data.round;
				this.data.firstBidWin = response.data.firstBidWin;
				this.data.status = response.data.status;
				if (response.data.name) {
					this.data.name = response.data.name;
				}
				if (typeof response.data.cancelReason !== "undefined") {
					this.data.cancelReason = response.data.cancelReason;
				}
				if (response.data.startDate) {
					this.data.startDate = response.data.startDate;
				}
				if (response.data.factEndDate) {
					this.data.endDate = response.data.factEndDate;
				} else if (response.data.stopDate) {
					if (this.data.endDate !== response.data.stopDate) {
						this.data.endDate = response.data.stopDate;

						const eventTime = moment(response.data.stopDate).format(
							"x"
						);
						const currentTime = moment().format("x");
						const disableTime = 10000;
						const diffTime = eventTime - currentTime - disableTime;
						let duration = moment.duration(diffTime);
						const interval = 1000;

						clearInterval(this.data.countdownInterval);
						this.data.countdownInterval = setInterval(() => {
							duration = moment.duration(
								duration - interval,
								"milliseconds"
							);

							if (duration.days() === 0) {
								this.data.countdown =
									duration.hours() +
									"ч : " +
									duration.minutes() +
									"м " +
									duration.seconds() +
									"сек";
							}
						}, interval);
					}
				}
				if (response.data.bids.length > 0) {
					response.data.bids.forEach((bid) => {
						if (bid.round === response.data.round) {
							if (response.data.status === 4) {
								if (bid.winner) {
									this.data.activeBid = bid;
								} else {
									this.data.bids.unshift(bid);
								}
							} else if (response.data.status === 6) {
								if (bid.winner) {
									this.data.activeBid = bid;
								}
							} else {
								if (bid.active) {
									this.data.activeBid = bid;
								} else {
									this.data.bids.unshift(bid);
								}
								if (
									!this.smallestContractorsBids.get(
										bid.contractor.id
									)
								) {
									this.smallestContractorsBids.set(
										bid.contractor.id,
										bid
									);
								} else if (
									this.smallestContractorsBids.get(
										bid.contractor.id
									).bet > bid.bet
								) {
									this.smallestContractorsBids.set(
										bid.contractor.id,
										bid
									);
								}
							}
						}
					});
					this.data.bids.sort((a, b) => b.id - a.id);
				}
				if (response.data.offers.length > 0) {
					response.data.offers.forEach((offer) => {
						if (offer.round === response.data.round) {
							if (response.data.status === 4) {
								if (offer.winner) {
									this.data.activeBid = offer;
								} else {
									this.data.offers.unshift(offer);
								}
							} else if (response.data.status === 6) {
								if (offer.winner) {
									this.data.activeBid = offer;
								}
							} else {
								this.data.offers.unshift(offer);
							}
						}
					});
				}
				// playedFlightsSelect start
				this.form.playedFlightsSelect.value = response.data.auctionRoutes.map(
					(auctionRoute) => auctionRoute.route.id
				);
				this.form.playedFlightsSelect.options = response.data.auctionRoutes.map(
					(auctionRoute) => {
						return {
							value: auctionRoute.route.id,
							text: auctionRoute.route.routeCode,
						};
					}
				);
				// playedFlightsSelect end
				// auction start
				if (response.data.firstBidWin) {
					this.startAuctionModal.selected === "firstBidWin";
				}
				if (response.data.enableOffers) {
					this.startAuctionModal.selected === "enableOffers";
				}
				if (response.data.offerDate) {
					this.startAuctionModal.offerDate = response.data.offerDate;
				}
				if (response.data.startPrice) {
					this.form.startPrice = response.data.startPrice;
				}
				if (response.data.endPrice) {
					this.form.endPrice = response.data.endPrice;
				}
				if (response.data.step) {
					this.form.stepRate = response.data.step;
				}
				if (response.data.withNDS) {
					this.form.VAT.value = 1;
				} else {
					this.form.VAT.value = 0;
				}
				if (response.data.auctionHistoryMessages) {
					this.data.history = response.data.auctionHistoryMessages.sort(
						(a, b) => b.id - a.id
					);
				}
				if (response.data.possibleParticipants.length > 0) {
					this.contractors.items = response.data.possibleParticipants;
				}
				// auction end
				// auctionRoute start
				response.data.auctionRoutes.forEach((auctionRoute) => {
					this.auctionRoutes[auctionRoute.id] = auctionRoute;
					Vue.set(this.data.playedFlights, auctionRoute.route.id, {});
					Vue.set(this.form.playedFlights, auctionRoute.route.id, {});
					Vue.set(
						this.data.playedFlights[auctionRoute.route.id],
						"auctionRouteId",
						auctionRoute.id
					);
					this.customersRouteRead(auctionRoute.route.id);
					if (auctionRoute.loadingTypes.length > 0) {
						Vue.set(
							this.form.playedFlights[auctionRoute.route.id],
							"loadingUnloading",
							{
								value: auctionRoute.loadingTypes.map(
									(item) => item.id
								),
							}
						);
					}
					if (auctionRoute.bodyTypes.length > 0) {
						Vue.set(
							this.form.playedFlights[auctionRoute.route.id],
							"bodyType",
							{
								value: auctionRoute.bodyTypes.map(
									(item) => item.id
								),
							}
						);
					}
					Vue.set(
						this.form.playedFlights[auctionRoute.route.id],
						"mkadPass",
						auctionRoute.mkadPass
					);
					Vue.set(
						this.form.playedFlights[auctionRoute.route.id],
						"retryDriver",
						auctionRoute.retryDriver
					);
          Vue.set(
						this.form.playedFlights[auctionRoute.route.id],
						"note",
						{id: null,
						value: null}
					);
					Vue.set(
						this.form.playedFlights[auctionRoute.route.id],
						"autoTariff",
						auctionRoute.autoTariff
					);
					Vue.set(
						this.form.playedFlights[auctionRoute.route.id],
						"price",
						auctionRoute.startPrice
					);
					Vue.set(
						this.form.playedFlights[auctionRoute.route.id],
						"endPrice",
						auctionRoute.endPrice
					);
				});
        if (response.data.routes) {
            response.data.routes.forEach((route) => {
				if (route.comment) {
					Vue.set(this.form.playedFlights[route.id], "note", {value: route.comment.comment, id: route.comment.id});
				}
            });       
        }
				// auctionRoute end
			}
			this.loading = false;
		},
		async customersRoutesList(name) {
			this.loading = true;
			const response = await customersRoutesList(this.customerId, {
				name: name,
				limit: 100,
			});
			if (response && response.status === 200) {
				this.form.playedFlight.options = response.data.items.map(
					(item) => {
						return { value: item.id, text: item.routeCode };
					}
				);
			}
			this.loading = false;
		},
		async transportVehiclesAuctionBodyTypes() {
			this.loading = true;
			const response = await transportVehiclesAuctionBodyTypes({
				limit: 100,
			});
			if (response && response.status === 200) {
				this.bodyTypeOptions = response.data.items.map((item) => {
					return { value: item.id, text: item.name };
				});
			}
			this.loading = false;
		},
		async transportVehiclesLoadingTypes() {
			this.loading = true;
			const response = await transportVehiclesLoadingTypes({
				limit: 100,
			});
			if (response && response.status === 200) {
				this.loadingUnloadingOptions = response.data.items.map(
					(item) => {
						return { value: item.id, text: item.name };
					}
				);
			}
			this.loading = false;
		},
		async transportVehiclesDimensionsTypes() {
			this.loading = true;
			const response = await transportVehiclesDimensionsTypes({
				limit: 100,
			});
			if (response && response.status === 200) {
				this.dimensionOptions = response.data.items.map((item) => {
					return { value: item.id, text: item.name };
				});
			}
			this.loading = false;
		},
		async customersRouteRead(routeId) {
			this.loading = true;
			if (routeId) {
				const response = await customersRouteRead(
					this.customerId,
					routeId
				);
				if (response && response.status === 200) {
					Vue.set(
						this.data.playedFlights[routeId],
						"routeCode",
						response.data.routeCode
					);
					Vue.set(
						this.data.playedFlights[routeId],
						"type",
						response.data.transportation.name
					);
					const clientTimezone = Intl.DateTimeFormat().resolvedOptions()
						.timeZone;
					if (response.data.planDateOfFirstPointArrive) {
						Vue.set(
							this.form.playedFlights[routeId],
							"firstPointArrivalDatetime",
							moment(
								response.data.planDateOfFirstPointArrive,
								"YYYY-MM-DDTHH:mm:ss"
							)
								.tz(clientTimezone)
								.format()
						);
					}
					if (response.data.planDateOfFirstPointLoading) {
						Vue.set(
							this.form.playedFlights[routeId],
							"firstPointLoadingDatetime",
							moment(
								response.data.planDateOfFirstPointLoading,
								"YYYY-MM-DDTHH:mm:ss"
							)
								.tz(clientTimezone)
								.format()
						);
					}
					if (response.data.organization) {
						Vue.set(
							this.form.playedFlights[routeId],
							"organization",
							response.data.organization.name
						);
					}
					Vue.set(
						this.form.playedFlights[routeId],
						"accelerated",
						response.data.boostFlag
					);
					await this.onRouteChange(
						response.data.routeWay.id,
						routeId
					);
				}
			}
			this.loading = false;
		},
		async onRouteChange(value, routeId) {
			this.loading = true;
			const response = await routeWayRead(value);
			if (response && response.status === 200) {
				Vue.set(
					this.data.playedFlights[routeId],
					"points",
					response.data.routeWayPoints.sort(
						(a, b) => a.rowNumber - b.rowNumber
					)
				);
				let lowestPoint = null;
				response.data.routeWayPoints.forEach((point) => {
					if (
						!lowestPoint ||
						point.rowNumber < lowestPoint.rowNumber
					) {
						lowestPoint = point;
					}
				});
				if (lowestPoint) {
					Vue.set(
						this.data.playedFlights[routeId],
						"firstRouteWayTimezone",
						lowestPoint.department.timeZone
					);
				}
				Vue.set(
					this.data.playedFlights[routeId],
					"fullDistance",
					response.data.routeWayPoints
						.reduce(function(accumulator, currentValue) {
							return accumulator + Number(currentValue.distance);
						}, 0)
						.toFixed(2)
				);

				Vue.set(
					this.data.playedFlights[routeId],
					"movementPoints",
					this.auctionRoutes[
						this.data.playedFlights[routeId].auctionRouteId
					].route.movementPoints.sort(
						(a, b) => a.rowNumber - b.rowNumber
					)
				);

				Vue.set(
					this.data.playedFlights[routeId],
					"guid",
					this.auctionRoutes[
						this.data.playedFlights[routeId].auctionRouteId
					].route.transportation.guid
				);

				if (
					this.data.playedFlights[routeId].guid ===
						"4b714ab4-4c44-4578-9a7a-06dbe77d8c45" ||
					this.data.playedFlights[routeId].guid ===
						"9ec087d0-917c-4fbb-b714-80ab7603fd6e"
				) {
					this.data.playedFlights[routeId].movementPoints.forEach(
						(point, index) => {
							if (
								this.data.playedFlights[routeId].movementPoints[
									index
								].pointAddress &&
								this.data.playedFlights[routeId].movementPoints[
									index
								].pointAddress !== ""
							) {
								this.data.playedFlights[routeId].points[
									index
								].department.mainWarehouse.address = this.data.playedFlights[
									routeId
								].movementPoints[index].pointAddress;
							}
						}
					);
				}

				Vue.set(
					this.data.playedFlights[routeId],
					"timeSummer",
					response.data.routeWayPoints
						.reduce(function(accumulator, currentValue) {
							return (
								accumulator + Number(currentValue.timeSummer)
							);
						}, 0)
						.toFixed(2)
				);
			}
			this.loading = false;
		},
		async onStart() {
			this.loading = true;
			if (this.data.status === 0) {
				const response = await customersAuctionClearRun(
					this.customerId,
					this.auctionsId,
					this.formStrSnakeStart
				);
				if (response && response.status === 200) {
					this.showSuccess("Аукцион подготовлен к запуску");
					this.$router.push({
						path: `/customers/id${this.customerId}/auction/id${response.data.id}`,
					});
				}
			} else if (this.data.status === 1) {
				const response = await customersAuctionChangeDates(
					this.customerId,
					this.auctionsId,
					this.formStrSnakeStart
				);
				if (response && response.status === 200) {
					this.showSuccess("Даты запуска аукциона успешно обновлены");
					this.$router.push({
						path: `/customers/id${this.customerId}/auction/id${response.data.id}`,
					});
				}
			} else if (this.data.status === 3) {
				const response = await customersAuctionDraft(
					this.customerId,
					this.auctionsId
				);
				if (response && response.status === 200) {
					this.loading = true;
					const response = await customersAuctionClearRun(
						this.customerId,
						this.auctionsId,
						this.formStrSnakeStart
					);
					if (response && response.status === 200) {
						this.showSuccess("Аукцион подготовлен к запуску");
						this.$router.push({
							path: `/customers/id${this.customerId}/auction/id${response.data.id}`,
						});
					}
					this.loading = false;
				}
			}
			this.loading = false;
		},
		async onDraft() {
			this.loading = true;
			const response = await customersAuctionDraft(
				this.customerId,
				this.auctionsId
			);
			if (response && response.status === 200) {
				this.showSuccess("Аукцион переведен в черновик");
				this.$router.push({
					path: `/customers/id${this.customerId}/auction/id${response.data.id}`,
				});
			}
			this.loading = false;
		},
		confirmDeleteAuction() {
			this.$bvModal
				.msgBoxConfirm("Вы уверены что хотите удалить аукцион?", {
					okTitle: "Да",
					cancelTitle: "Назад",
				})
				.then((confirm) => {
					if (confirm) {
						this.onCancel();
					}
				});
		},
		async onCancel() {
			this.loading = true;
			const response = await customersAuctionCancel(
				this.customerId,
				this.auctionsId
			);
			if (response && response.status === 200) {
				this.showSuccess("Аукцион отменен");
				this.$router.push({ path: "/auctions/customer" });
			}
			this.loading = false;
		},
		async setWinner(type, id) {
			this.loading = true;
			let body = {};
			if (type === "bid") {
				body = { bid_id: id };
			} else if (type === "offer") {
				body = { offer_id: id };
			}
			const response = await customersAuctionSetWinner(
				this.customerId,
				this.auctionsId,
				body
			);
			if (response && response.status === 200) {
				this.showSuccess("Победитель назначен");
				this.$router.push({ path: "/auctions/customer" });
			}
			this.loading = false;
		},
	},
};
</script>

<style lang="scss">
.auction-info {
	overflow: hidden;
	background: #f3f4f5;
	border-radius: 5px;

	&__header {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		margin-left: -15px;
		margin-right: -15px;
		padding: 5px 10px;
		border-bottom: 1px solid #c8ced3;

		&-item {
			padding-left: 15px;
			padding-right: 15px;
		}
	}
	&__body {
		padding: 5px 10px;

		&-title {
			margin-top: 24px;
			margin-bottom: 24px;
			font-size: 36px;
			font-weight: 700;
			text-align: center;
		}
		&-subtitle {
			margin-top: 24px;
			margin-bottom: 24px;
			font-size: 16px;
			font-weight: 700;
			text-align: center;
		}
		&-dates {
			display: flex;
			max-width: 480px;
			margin: 24px auto;
		}
		&-start-date {
			flex: 0 0 50%;
			max-width: 50%;
			font-size: 14px;
		}
		&-end-date {
			flex: 0 0 50%;
			max-width: 50%;
			font-size: 14px;
			text-align: right;
		}
		&-buttons {
			display: flex;
			justify-content: center;
			margin-top: 60px;
		}
		&-button {
		}
	}
	&__previous-bet {
		position: relative;
		min-height: 100%;
		padding: 5px 10px;

		&:after {
			position: absolute;
			top: 0;
			right: 0;
			transform: translateX(50%);
			display: block;
			width: 1px;
			height: 100%;
			background-color: #c8ced3;
			content: "";
		}

		&-title {
			font-weight: 700;
			font-size: 14px;
		}
		&-list {
			max-height: 271px;
			overflow: auto;
			background-color: white;
			border: 1px solid #c8ced3;
		}
		&-item {
			padding: 2px 5px;
			border-bottom: 1px solid #c8ced3;

			&:last-child {
				border-bottom: 0;
			}

			&-header {
				display: flex;
				justify-content: space-between;
				margin-bottom: 10px;
			}
			&-date {
				font-weight: 700;
				font-size: 11px;
			}
			&-type {
				font-size: 11px;
			}
			&-info {
				position: relative;
				display: flex;
				justify-content: space-between;
				margin-top: 10px;
				margin-bottom: 10px;

				&:before {
					position: absolute;
					top: 50%;
					left: 50%;
					transform: translate(-50%, -50%);
					width: 28px;
					height: 2px;
					background-color: #20a8d8;
					content: "";
				}
			}
			&-contractor {
				flex: 0 0 50%;
				max-width: 50%;
				padding-right: 15px;
				font-weight: 700;
				font-size: 13px;
			}
			&-price {
				flex: 0 0 50%;
				max-width: 50%;
				padding-left: 15px;
				font-weight: 700;
				font-size: 14px;
			}
			&-buttons {
				display: flex;
				justify-content: center;
				margin-top: 10px;
			}
			&-button {
			}
		}
		&-item-detail {
			padding: 2px 5px;
			font-size: 11px;
		}
	}
	&__last-bet {
		position: relative;
		padding: 5px 10px;

		&-title {
			margin-bottom: 24px;
			font-weight: 700;
			font-size: 36px;
			text-align: center;
		}
		&-time {
			position: absolute;
			top: 5px;
			right: 10px;
			margin-top: 0;
			margin-bottom: 0;
			font-weight: 700;
			line-height: 27px;
			text-align: right;
			@media screen and (max-width: 767px) {
				position: static;
			}
		}
		&-current-bid {
			display: flex;
			align-items: flex-end;
			margin-top: 16px;
			margin-bottom: 16px;
			@media screen and (max-width: 767px) {
				flex-wrap: wrap;
			}

			&-title {
				min-width: 200px;
				font-weight: 700;
				font-size: 14px;
				@media screen and (max-width: 767px) {
					width: 100%;
				}
			}
			&-value {
				font-weight: 700;
				font-size: 28px;
				line-height: normal;
				color: #4dbd74;
				@media screen and (max-width: 767px) {
					width: 100%;
				}

				&_no-winner {
					font-style: normal;
					font-weight: 700;
					font-size: 14px;
					color: #c8ced3;
				}

				&-vat {
					font-size: 12px;
					color: #636f83;
				}
			}
		}
		&-current-bid-detail {
			display: flex;
			align-items: flex-end;
			margin-top: 16px;
			margin-bottom: 16px;

			&-title {
				min-width: 200px;
				font-size: 14px;
			}
			&-value {
				font-size: 14px;
				line-height: normal;

				&_no-winner {
					font-style: normal;
					font-size: 14px;
					color: #c8ced3;
				}

				&-vat {
					font-size: 12px;
					color: #636f83;
				}
			}
		}
		&-current-contractor {
			display: flex;
			align-items: flex-end;
			margin-top: 16px;
			margin-bottom: 16px;
			@media screen and (max-width: 767px) {
				flex-wrap: wrap;
			}

			&-title {
				min-width: 200px;
				font-weight: 700;
				font-size: 14px;
				@media screen and (max-width: 767px) {
					width: 100%;
				}
			}
			&-value {
				font-style: italic;
				font-weight: 700;
				font-size: 18px;
				line-height: normal;
				color: #20a8d8;
				@media screen and (max-width: 767px) {
					width: 100%;
				}

				&_no-winner {
					font-style: normal;
					font-weight: 700;
					font-size: 14px;
					color: #c8ced3;
				}
			}
		}
		&-current-time {
			display: flex;
			align-items: flex-end;
			margin-top: 16px;
			margin-bottom: 16px;
			@media screen and (max-width: 767px) {
				flex-wrap: wrap;
			}

			&-title {
				min-width: 200px;
				font-weight: 700;
				font-size: 14px;
				@media screen and (max-width: 767px) {
					width: 100%;
				}
			}
			&-value {
				font-weight: 700;
				font-size: 14px;
				color: #20a8d8;
				@media screen and (max-width: 767px) {
					width: 100%;
				}

				&_no-winner {
					font-style: normal;
					font-weight: 700;
					font-size: 14px;
					color: #c8ced3;
				}
			}
		}
		&-buttons {
			display: flex;
			justify-content: center;
			margin-top: 20px;
		}
		&-button {
		}
	}
}

.history-auction {
	&__title {
	}
	&__desc {
	}
	&__list {
		list-style: inside none;
		overflow: auto;
		height: 444px;
		padding: 10px 5px;
		margin-bottom: 0;
		background-color: #2b2b2b;

		&-item {
			margin-bottom: 12px;

			&:last-child {
				margin-bottom: 0;
			}

			&-date {
				color: #797979;
			}
			&-changes {
				padding-left: 0;
				list-style-position: inside;
			}
		}
	}
}

.VueTables__table {
	margin-bottom: 2rem;
}

.route-and-flight-auction-details {
	position: relative;
	margin-top: 24px;

	&:before {
		position: absolute;
		top: 0;
		left: 50%;
		transform: translateX(-50%);
		display: block;
		width: 1px;
		height: 100%;
		background-color: #c8ced3;
		content: "";

		@media screen and (max-width: 1199px) {
			display: none;
		}
	}

	&__dates {
		@media screen and (max-width: 1199px) {
			position: relative;
			margin-top: 40px;
			padding-top: 20px;

			&:before {
				position: absolute;
				top: 0;
				left: 0;
				display: block;
				width: 100%;
				border: 1px solid #c8ced3;
				content: "";
			}
		}
	}
	&__title {
		margin-top: 0;
		margin-bottom: 8px;
	}
	&__route-container {
		position: relative;
		padding-top: 61px;
		padding-bottom: 37px;
		height: 100%;

		&-title {
			position: absolute;
			top: 0;
		}
		&-desc {
			position: absolute;
			top: 24px;
		}
		&-total {
			position: absolute;
			margin-top: 0.5rem;
		}

		&-second {
			position: absolute;
			margin-top: 2.125rem;
		}
	}
	&__date {
		display: flex;
		flex-wrap: wrap;
		margin-bottom: 32px;

		&-title {
			flex: 0 0 100%;
			max-width: 100%;
		}
		&-number {
			flex: 0 0 50%;
			max-width: 50%;
			padding-right: 15px;
		}
		&-time {
			flex: 0 0 50%;
			max-width: 50%;
			padding-left: 15px;
		}
	}
	&__type {
	}
	&__transport {
		position: relative;
		margin-top: 32px;
		padding-top: 20px;

		&:before {
			position: absolute;
			top: 0;
			left: 0;
			display: block;
			width: 100%;
			border: 1px dashed #c8ced3;
			content: "";
		}
	}
	&__form-group {
		&:last-child {
			margin-bottom: 0;
		}

		.col-form-label {
			font-weight: bold;
		}
	}
}

.route-auction {
	position: relative;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	max-width: 480px;
	height: 100%;

	&:before {
		position: absolute;
		top: 0;
		left: 50%;
		transform: translateX(-50%);
		display: block;
		width: 3px;
		height: 100%;
		background-color: #c8ced3;
		content: "";
	}

	&__item {
		position: relative;
		z-index: 1;
		max-width: 480px;
		padding: 10px 6px;
		margin-top: 18px;
		background: #f3f4f5;
		border: 1px solid #c8ced3;

		&:first-child {
			margin-top: 0;

			&:before {
				display: none;
			}
		}

		&:before {
			position: absolute;
			top: 0;
			left: 50%;
			transform: translate(-50%, -50%);
			border: 10px solid transparent;
			border-top: 10px solid #c8ced3;
			content: "";
		}

		&-header {
			display: flex;
			flex-wrap: wrap;
			justify-content: space-between;
			@media screen and (max-width: 767px) {
				flex-wrap: wrap;
			}
		}
		&-number {
			padding-right: 20px;
			white-space: nowrap;
		}
		&-type {
			width: auto;
			min-width: 136px;
			padding: 0 5px;
		}
		&-distance {
			padding-left: 20px;
			white-space: nowrap;
			@media screen and (max-width: 767px) {
				width: 100%;
				padding-left: 0;
			}
		}
		&-address {
			margin-top: 12px;
			margin-bottom: 0;
		}
		&-footer {
			padding-top: 28px;
			display: flex;
			justify-content: space-between;
		}
		&-date {
		}
		&-time {
		}
	}
}

.terms-and-participants {
	&__contractors {
		min-height: 537px;
		padding: 1rem;
	}
	&__start-price {
		position: relative;
		height: 100%;
		padding: 1rem;

		&:before {
			position: absolute;
			top: 0;
			left: -0.5px;
			display: block;
			width: 1px;
			height: 100%;
			background-color: #c8ced3;
			content: "";
			@media screen and (max-width: 991px) {
				left: 0;
				width: 100%;
				height: 1px;
			}
		}
	}
	&__auction-duration {
		position: relative;
		margin-top: 32px;
		padding-top: 20px;

		&:before {
			position: absolute;
			top: 0;
			left: 0;
			display: block;
			width: 100%;
			border: 1px dashed #c8ced3;
			content: "";
		}
	}
}

.tab-content {
	.tab-pane.terms-and-participants {
		padding: 0;
	}
}
</style>
