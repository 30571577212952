

export const auctionCancelReason = {
  methods: {
    getCancelReasonText(cancelReason) {
      switch (cancelReason) {
      case 0:
        return 'Победитель не забрал в обработку.';break;
      case 1:
        return 'Нет победителя. Аукцион не был перезапущен.';break;
      case 2:
        return 'Отменен Заказчиком после завершения аукциона.';break;
      case 3:
        return 'Отменен Заказчиком до завершения аукциона.';break;
      case 4:
        return 'Отменен Заказчиком, аукцион не запускался.';break;
      default:
        return '';
      }
    },
  },
};
